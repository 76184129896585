<template>
  <data-table
    v-model="pdfSettings"
    :show-filters="false"
    :actions="pdfSettings.actions"
    :permission="permission"
  ></data-table>
</template>

<script>
export default {
  name: 'notification-settings',
  components: {},
  data() {
    return {
      permission: {
        module: 'Einstellungen',
        subModule: 'Einstellungen',
        childModule: 'Allgemein Einstellung',
        operation: 'edit',
        options: ['All', 'Only own personal data'],
      },
      pdfSettings: {
        resource: 'organisation/setting/notification',
        actions: {
          index: null,
          list: ['edit'],
          callback: state => {
            this.$bus.$emit('show-modal', {
              show: true,
              title: 'Benachrichtigung Einstellungen bearbeiten',
              component: 'new-notification-settings',
              request: 'edit',
              message: `Möchten Sie wirklich die Benachrichtigung entfernen?`,
              resource: `organisation/setting/notification/${state.payload.id}/`,
              data: state.payload,
              maxWidth: 'w-1/3',
              event: 'reload-notification-settings-data-table',
              type: 'edit',
            })
          },
        },
        columns: [
          {
            label: 'Module',
            field: ['module.app_label'],
            type: 'text',
            headerAlign: 'left',
            width: '20',
            prefix: '',
            postfix: '',
          },
          {
            label: 'Type',
            field: ['type'],
            headerAlign: 'left',
            width: '50',
            type: 'text',
            prefix: '',
            postfix: '',
          },
          {
            label: 'Status',
            field: ['active'],
            headerAlign: 'left',
            width: '50',
            type: 'bool',
            prefix: '',
            postfix: '',
          },
          { label: '', field: ['id'], type: 'checkbox', width: '10' },
        ],
        rows: [],
      },
      breadcrumbs: {
        title: [
          { link: '/settings', crumb: 'Allgemeine Einstellungen' },
          { link: '/settings/general', crumb: 'Stammdaten' },
          { crumb: 'Benachrichtigung Einstellungen' },
        ],
        pageTitle: 'Benachrichtigung Einstellungen',
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)
    },
  },
}
</script>

<style></style>
